<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('course')" class="mb-1">
                        <ValidationProvider name="course_id" :rules="formData.pool_id?'':'required'"
                                            v-slot="{ errors }">
                            <course-auto-complete v-model="formData.course_id"
                                                  :validate-error="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="mt-2" :label="$t('course_activity')">
                        <ValidationProvider name="course_activity_id" v-slot="{ errors }">
                            <CourseActivitySelectbox
                                v-model="formData.course_activity_id"
                                :validate-error="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>

                    <b-form-group :label="$t('number')">
                        <ValidationProvider name="number" rules="required" v-slot="{ valid, errors }">
                            <b-form-input type="number" v-model="formData.number">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>

                    <b-form-group :label="$t('effect_ratio')">
                        <ValidationProvider name="effect_ratio" rules="required" v-slot="{ valid, errors }">
                            <b-form-input type="number" v-model="formData.effect_ratio">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"

import CourseProgramOutcomeServices from "@/services/CourseProgramOutcomeServices";
import CourseActivitySelectbox from "@/components/interactive-fields/CourseActivitySelectbox.vue";
import CourseAssessmentServices from "@/services/CourseAssessmentServices";

export default {
    components: {
        CourseActivitySelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                course_id: null,
                course_activity_id:null,
                number:null,
                effect_ratio:null
            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            CourseAssessmentServices.show(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData={
                        course_id: data.course_id,
                        course_activity_id:data.course_activity_id,
                        number:data.number,
                        effect_ratio:data.effect_ratio
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    CourseAssessmentServices.update(this.formId, this.formData)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
